import cx from 'classnames'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useHomepageTabsContext } from '../../contexts/HomepageTabsContext'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { Button2 } from '../button2'
import { Container } from '../container'
import styles from './hero.module.css'
import { CityNameDesk, CityNameMob } from './images/icons'
import { HeroRating } from '../hero-rating'
import partnerLogo from '../hero3/partner-logo.png'

export const HeroBrno = () => {
    const scroll = useSmoothScroll()
    const { dispatchAction } = useHomepageTabsContext()
    const { t } = useTranslation()

    const openCalcForm = selector => {
        dispatchAction({ type: 'OPEN_TAB1' })
        scroll.animateScroll(document.getElementById(selector))
    }

    const openOrderForm = () => {
        window.location.href = '/order-food-online'

    }

    return (
        <section className={styles.hero}>
            <Container className={styles.container} isWide={true}>
                <div className={styles.heroInner}>
                    <div className={styles.heroInfo}>
                        <HeroRating />
                        <h1 className={styles.heroTitle}>
                            <Trans i18nKey="home.hero.titleBlueghost">
                                <span>Nejlépe</span> hodnocená krabičková dieta
                            </Trans>
                        </h1>
                        <div className={styles.mobileCityName}>
                            <CityNameMob />
                        </div>
                        <div className={styles.deskCityName}>
                            <CityNameDesk />
                        </div>
                        <div
                            className={cx(styles.buttons, styles.buttonWrapper)}
                            id="sticky-buttons"
                        >
                            <Button2
                                color="primary"
                                className={styles.button}
                                handleClick={e => {
                                    openCalcForm('calculator2')
                                }}
                            >
                                <svg
                                    className={styles.buttonIcon}
                                    fill="none"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    width="25"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m13.5413 15.625h2.0834m-10.41669 6.25v-16.66667c0-.55253.21949-1.08244.61019-1.47314s.92061-.61019 1.47314-.61019h10.41666c.5525 0 1.0824.21949 1.4731.61019s.6102.92061.6102 1.47314v16.66667l-3.125-2.0833-2.0833 2.0833-2.0833-2.0833-2.0834 2.0833-2.08329-2.0833zm4.16666-14.58333h6.25003zm0 4.16663h6.25003z"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5625"
                                    />
                                </svg>
                                {t('home.hero.cta-2Blueghost')}
                            </Button2>
                            <Button2
                                color="secondary"
                                className={styles.button}
                                handleClick={
                                    openOrderForm
                                }
                            >
                                {t('home.hero.cta-1')}
                            </Button2>
                        </div>
                    </div>

                </div>
                <a href='https://www.formfactory.cz/' target='_blank' className={styles.partnerBanner}>
                    <Container isWide={true}>
                        <div className={styles.partnerBannerInner}>
                            <span className={styles.partnerBannerTitle}>
                                {t('home.hero3.title')}
                            </span>
                            <img
                                src={partnerLogo}
                                className={styles.partnerBannerImg}
                                alt="partner logo"
                            />
                        </div>
                    </Container>
                </a>
            </Container>
        </section>
    )
}
